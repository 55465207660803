<template>
  <view-wrapper>
    <div class="min-h-[700px] pt-[24px] pr-[40px] pb-[50px] pl-[4px] bg-[#fff]">
      <a-tabs tabPosition="left" class="setting-tabs">
        <a-tab-pane key="basic" tab="基本设置">
          <div class="h-[28px] leading-[28px] font-bold text-[20px]">基本设置</div>
          <div>
            <div class="my-[24px] text-[18px] leading-[22px]">个人信息</div>
            <div class="flex ml-[5px] mb-[15px]">
              <div class="w-[45px] mr-[12px] text-right">姓名</div>
              <div class="w-[325px] border-b-[1px] border-[#d9d9d9]">{{ store.state.user.name }}</div>
            </div>
            <div style="margin-left: 5px" class="flex">
              <div class="w-[45px] mr-[12px] text-right">手机号</div>
              <div class="w-[325px] border-b-[1px] border-[#d9d9d9]">{{ store.state.user.cellphone }}</div>
            </div>
          </div>
          <div>
            <div class="my-[24px] text-[18px] leading-[22px]">团队信息</div>
            <div class="ml-[20px]">
              <div
                class="w-[378px] h-[42px] border-b-[1px] border-[#d9d9d9] leading-[42px] cursor-pointer"
                @click="invoiceVisible = true"
              >
                <span>发票</span>
                <span class="float-right text-[#bfbfbf]">
                  <RightOutlined></RightOutlined>
                </span>
              </div>
              <div
                class="w-[378px] h-[42px] border-b-[1px] border-[#d9d9d9] leading-[42px] cursor-pointer"
                @click="addressVisible = true"
              >
                <span>收货地址</span>
                <span class="float-right text-[#bfbfbf] r">
                  <RightOutlined></RightOutlined>
                </span>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="price" tab="报价设置">
          <div class="h-[28px] leading-[28px] font-bold text-[20px]">报价设置</div>
          <div>
            <div class="my-[24px] text-[18px] leading-[22px]">报价要求</div>
            <div>
              <a-radio-group name="partsGroup" v-model:value="inquerySetting.required.code" @change="configChange">
                <span class="mr-[10px]">要求商家必填配件编码</span>
                <a-radio value="Y">是</a-radio>
                <a-radio value="N">否</a-radio>
              </a-radio-group>
            </div>
            <div class="my-[24px] text-[18px] leading-[22px]">开票要求</div>
            <div>
              <a-radio-group name="invoiceGroup" v-model:value="inquerySetting.invoiceType.code" @change="configChange">
                <a-radio :value="item.code" v-for="item of invoiceData" :key="item.code">{{ item.message }}</a-radio>
              </a-radio-group>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
      <drawer-inovice v-model:visible="invoiceVisible"></drawer-inovice>
      <drawer-address v-model:visible="addressVisible"></drawer-address>
    </div>
  </view-wrapper>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue'
import { Tabs as ATabs, TabPane as ATabPane, RadioGroup as ARadioGroup, Radio as ARadio } from 'ant-design-vue'
import { useStore, useAjax } from '@vue-mfe/utils'
import { RightOutlined } from '@ant-design/icons-vue'

import DrawerInovice from '@client/components/drawer-invoice.vue'
import DrawerAddress from '@client/components/drawer-address.vue'

const store = useStore()

const addressVisible = ref(false)

const invoiceVisible = ref(false)

const inquerySetting = reactive(
  {
    required: {
      code: ''
    },
    invoiceType: {
      code: ''
    }
  }
)

// 获取开票要求
const { data: invoiceData } = useAjax(
  {
    action: 'GET /common/lookup/:lookupType',
    params: 'IVCTP',
    convert: {
      client (data: any) {
        data.push(data.shift())
        return data
      }
    }
  }
)

const configChange = () => {
  changeConfigAjax()
}

const { run: changeConfigAjax } = useAjax(
  {
    action: 'PUT /client/common/config',
    params: () => inquerySetting,
    lazy: true
  }
)

// 获取报价设置
useAjax(
  {
    action: 'GET /client/common/config',
    convert: {
      client (data) {
        inquerySetting.required.code = data[0].required.code
        inquerySetting.invoiceType.code = data[0].invoiceType.code
        return data
      }
    }
  }
)
</script>

<style scoped>
.setting-tabs :deep(.ant-tabs-tab-active.ant-tabs-tab) {
  background: rgba(107, 216, 216, 0.2);
  color: #0d8d8d;
}
</style>
